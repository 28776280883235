import OrderListPage from '@/pages/Order/OrderListPage';
import OrderViewPage from '@/pages/Order/OrderViewPageV2';
import CheckoutSummaryPage from '@/pages/Checkout/SummaryPage/SummaryPage';
import SupplierViewPage from '@/pages/Supplier/SupplierViewPage';
import OrderStartPickupPage from '@/pages/Order/OrderStartPickupPage';
import OrderTransportListPage from '@/pages/Order/OrderTransportListPage';
import GenericOrderDetails from './components/GenericOrderDetails.vue';
import { routerInstance } from '@/utils/instances';
import { findRouteName } from '../routerUtils';

export default {
    path: 'orders',
    name: 'order-list',
    component: OrderListPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['listOrders'],
    },
    children: [
        {
            include: 'order-view',
        },
        {
            path: 'quote/:quoteId',
            name: 'order-quote-view',
            component: CheckoutSummaryPage,
            props: true,
            meta: {
                next: 'home',
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                if (from && from.name) {
                    localStorage.setItem('checkout-summary-check-previous', from.name);
                }
                to.meta.previous = localStorage.getItem('checkout-summary-check-previous');
                next();
            },
        },
        {
            path: ':orderId',
            name: 'generic-order-details',
            component: GenericOrderDetails,
            props: route => ({
                orderId: route.params.orderId,
                onPanelClose: () => {
                    const router = routerInstance.get();
                    const route = router.currentRoute;
                    const name = findRouteName('order-list');
                    router.push({ name, query: route.query });
                },
            }),
        },
    ],
};

export const orderViewRoute = {
    path: 'order/:orderId',
    name: 'order-view',
    component: OrderViewPage,
    props: true,
    children: [
        {
            path: 'transports/:lineItemGroupId?',
            name: 'order-transports',
            component: OrderTransportListPage,
            props: true,
            meta: {
                previous: 'order-view',
            },
            children: [
                {
                    include: 'transport-view',
                },
            ],
        },
        {
            path: 'supplier/:factoryId',
            name: 'order-supplier-view',
            component: SupplierViewPage,
            props: true,
        },
        {
            path: 'pickup/start',
            name: 'order-pickup-start',
            component: OrderStartPickupPage,
            props: true,
            children: [
                {
                    include: 'vehicle-new',
                },
            ],
        },
    ],
};
